import type { Directive } from 'vue'

// "mousedown", "mouseup", etc. can still be fired on touch devices (tested)
type Evt = PointerEvent
type Handler = (evt: Evt) => void

const wrapHandler = (handler: Handler) => (evt: Evt) => {
  if (evt.pointerType === 'mouse') {
    handler(evt)
  }
}

const hasScrollbar = (el: Element) => el.scrollWidth > el.clientWidth

const updateStyleCursor = (el: HTMLElement, cursor: string) => {
  if (hasScrollbar(el)) {
    el.style.cursor = cursor
  } else {
    el.style.removeProperty('cursor')
  }
}

export const vDragScrollX = (() => {
  let onDownOnce: Handler
  let onDown: Handler
  let onMove: Handler
  let onUp: Handler

  return {
    mounted(el) {
      let isDown = false
      let mouseX = -1

      updateStyleCursor(el, 'grab')

      onDownOnce = wrapHandler(() => {
        el.style.scrollSnapType = 'none'
        el.style.scrollBehavior = 'auto'
      })

      onDown = wrapHandler(evt => {
        if (!evt.button) {
          isDown = true
          mouseX = evt.clientX
          updateStyleCursor(el, 'grabbing')
        }
      })

      onMove = wrapHandler(evt => {
        if (!isDown) {
          return
        }

        evt.preventDefault()

        const dx = evt.clientX - mouseX

        if (!dx) {
          return
        }

        el.scrollLeft -= Math.sign(dx) * Math.max(Math.abs(dx), 1)
        mouseX = evt.clientX
      })

      onUp = wrapHandler(evt => {
        if (!evt.button) {
          isDown = false
          updateStyleCursor(el, 'grab')
        }
      })

      el.addEventListener('pointerdown', onDownOnce, { once: true })
      el.addEventListener('pointerdown', onDown)
      window.addEventListener('pointermove', onMove)
      window.addEventListener('pointerup', onUp)
    },

    unmounted(el) {
      el.removeEventListener('pointerdown', onDownOnce)
      el.removeEventListener('pointerdown', onDown)
      window.removeEventListener('pointermove', onMove)
      window.removeEventListener('pointerup', onUp)
    }
  } satisfies Directive<HTMLElement>
})()
